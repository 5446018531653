import React from 'react';

interface StickySectionProps {
    color: string;
    id: string;
    height?: string | null;
    children: React.ReactNode;
}

const StickySection: React.FC<StickySectionProps> = ({ color, id, children, height }) => {
    if (id === "section1"){
        return (
            <div id={id} style={{
                height: height || '100vh',
                backgroundColor: color,
                position: 'sticky',
                top: 0,
                zIndex: 1
            }}>
                {children}
            </div>
        );
    }
    return (
        <div id={id} style={{
        height: height || '100vh',
            backgroundColor: color,
            position: 'sticky',
            top: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1
    }}>
    {children}
    </div>
);
};

export default StickySection;
