export const removeElementsByClass = (className: string): void => {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
        elements[0].parentNode?.removeChild(elements[0]);
    }
};

export const getElByClassName = (className: string): Element | null => {
    const elements = document.getElementsByClassName(className);
    return elements[0] || null;
};

export const isMobileDevice = (): boolean => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const isMobileDeviceV2 = (): boolean => {
    return window.innerWidth < 500;
};

export const isMobileDeviceV3 = (minWidth: number): boolean => {
    return window.innerWidth < minWidth;
};
export const removeClassCSS = (className: string) => {
    let els = document.getElementsByClassName(className);
    Array.prototype.forEach.call(els, function(el) {
        el.classList.remove(className);
    });

}
