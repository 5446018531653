import React from "react";
import {CloseButton, CloseProps} from "../_inner/Settings";
import "./About.css"
import {Green} from "../_inner/Constants";


export const Diary: React.FC<CloseProps> = (CloseProps) => {
    return (
        <>

        <div style={{backgroundColor: Green}}>
            <CloseButton setOpen={CloseProps.setOpen}/>
            <div className={"about main-style"} id={"about"}>
                <div className={"grid"}>
                    <div className={"grid-item xs-2 any-text"}>
                        <div className={"text-title any-text"}>ДНЕВНИК</div>
                        <p></p>
                        <div className="text-title any-text">
                            Шаг 1
                        </div>
                        <p className="any-text responsive-text">[11 июля]</p>
                        <p className="any-text responsive-text">
                            Переживала, что мой первый шаг будет сверху – устрашающая белая пустота: почему-то в моей
                            голове всегда представлялись ходы снизу. Концепт первого шага придумала, пока шла по улице и
                            смотрела на облака: внутреннее болезненное ощущение разрозненности совместилось с ощущением,
                            что
                            небо остаётся целостным, хотя иногда в каждом его кусочке выглядит по-разному. Подумала, что
                            это
                            подходит и под проект, гипотетически и формально объединяющий разные языки.
                        </p>
                        <p className="any-text responsive-text">
                            В день накануне села формулировать. Получилось громоздко. Подумала, что текст всегда пишется
                            как
                            некая продолжительность, а тут нужно сделать небольшой кусок, который был бы отдельным, но
                            не был
                            целостным – как замкнутая фраза, содержащая мысль/идею/концепт/образ, но предполагающая
                            возможность
                            разных продолжений.
                        </p>
                        <p className="any-text responsive-text">
                            Для этого нужно сформулировать небольшое количество слов так, как будто они из моего
                            большого текста
                            – иначе внутренне казалось, что отрывистость сразу похожа на тексты Ани или Инны, которые
                            всё равно
                            присутствуют где-то там в голове во время совместной работы. Села перечитывать свои тексты –
                            и
                            длинные последние, и вернулась к «апологии тьмы» – как раз из-за её визуальности и в целом
                            мне не
                            очень свойственного, но всё-таки моего языка. Под конец удалось сформулировать что-то
                            похожее на моё
                            – осталась довольна.
                        </p>
                        <p className="any-text responsive-text">
                            Сначала хотела вводить реплику под конец, чтобы сразу увидеть другие, но потом подумала, что
                            не
                            страшно начать. Минут 40 двигала туда-сюда нижние строки – оговоренный час как ограниченное
                            пространство для перфекционизма, в какой-то момент усилием воли свернула окно.
                        </p>
                        <p className="any-text responsive-text">
                            Испугалась, не слишком ли тотальной получилась реплика про облака разных форм, на которые
                            стали
                            похожи наши первые шаги – но потом решила, что не страшно – впоследствии она затеряется
                            среди
                            других.
                        </p>
                        <p className="any-text responsive-text">
                            там где совокупность
                        </p>
                        <p className="any-text responsive-text">
                            всех возможных форм облаков называется небо
                        </p>
                        <p className="any-text responsive-text">
                            всех возможных
                            форм облаков
                        </p>
                        <p className="any-text responsive-text">
                            называется небо
                        </p>
                        <p></p>
                        <br />
                        <div className="text-title any-text">
                            Шаг 2
                        </div>
                        <p className="any-text responsive-text">
                            [15 июля]
                        </p>
                        <p className="any-text responsive-text">
                            Пробежалась глазами по репликам сразу после первого шага, но плохо запомнила (в
                            том
                            смысле, что над ними не думала внутри повседневности). Потом были сильные личные потрясения,
                            ни о
                            чем другом не могла думать совсем.
                        </p>
                        <p className="any-text responsive-text">
                            За день или два до инпута посмотрела на фрейм и поняла, что интуитивно свой следующий шаг
                            хочу
                            поместить левее и ниже Аниного. Продолжение? или растерянность от непонимания, как
                            выстраивать
                            взаимодействие – а здесь прямой ответ/добавление (с репликой Инны так было бы сложнее).
                        </p>
                        <p className="any-text responsive-text">
                            Начала придумывать текст. В первую очередь – исходя из внутреннего сегодняшнего состояния,
                            потом
                            Аниной реплики – потом своей – и с опорой на темы/формальное устройство первого шага Инны.
                            Хотелось
                            сделать (грамматически, синтаксически, тематически) как построение дальше своего – но и
                            продолжение
                            других реплик тоже (трудно разорваться/сориентироваться/соединить).
                        </p>
                        <p className="any-text responsive-text">
                            Заранее расположила слова интуитивно. Во время расположения шага старалась (перфекционизм)
                            оставить
                            желаемое/ощутимое (рядом с Аней) и сохранить визуальное соответствие с другими (своей –
                            наискосок,
                            Инниной &lt;хорошее слово&gt; – по горизонтали).
                        </p>
                        <p className="any-text responsive-text">
                            Боялась подходить слишком близко к чужой: кажется, Аня тоже что-то хотела там сделать – чего
                            и
                            опасалась в таком формате – задумывания на одно и то же место &lt;в этот раз намеренно зашла
                            в начале
                            часа: но успела уловить ещё до
                            введения схожесть с новой репликой
                            Инны&gt;.
                        </p>
                        <p className="any-text responsive-text">
                            Уже сложность с желанием немного редактировать/сдвинуть предыдущее – хотя и стараюсь
                            воспринимать
                            все прошлые реплики (чужие в т.ч.) окаменевшими, как бы выстраивая новое вокруг них – &lt;и
                            тем больше
                            переживание
                            о каждом
                            шаге из-за
                            его
                            неизменимости
                            после&gt;.
                        </p>
                        <p className="any-text responsive-text">
                            С другой стороны, исследовательский характер расслабляет и радует: чувствую невозможность
                            'совершить
                            ошибку' – и длительность процесса позволяет иногда выпадать из него (частично – как
                            возможность
                            спонтанности, неполной продуманности, отсутствия тотального контроля) – как невозможность
                            перманентной готовности к поэтическому.
                        </p>
                        <p className="any-text responsive-text">
                            или точки покрывают области дня
                        </p>
                        <p className="any-text responsive-text">
                            собираясь в знаки блуждающие
                        </p>
                        <p className="any-text responsive-text">
                            по поверхности
                        </p>
                        <p></p>
                        <br />
                        <div className="text-title any-text">
                            Шаг 3
                        </div>
                        <p className="any-text responsive-text">
                            [18 июля]
                        </p>
                        <p className="any-text responsive-text">
                            Мало времени получилось: приступила только накануне, совмещала опыт копания в камнях тем же
                            вечером и все предыдущие реплики. Сначала что-то о своём, краткое, потом с опорой на прошлый
                            шаг: после 'затвора' и 'молнии' поняла, что хочу сделать про это – и почти сразу форма
                            молний: думала все слова сделать из них – но показалось, что слишком нагружено будет – и тут
                            же пришло примерное место.
                        </p>
                        <p className="any-text responsive-text">
                            В этот раз неожиданный сдвиг планов, поэтому пришлось загружать с телефона в короткий
                            период: накануне много тренировалась и заранее расположила буквы, проверила на черновой
                            доске.
                        </p>
                        <p className="any-text responsive-text">
                            Всё равно очень долго вводила потом, двигала в разные стороны – не уверена, что хорошо
                            получилось, молнии друг с другом не очень срослись (хотя проверяла не раз!): думаю тайно их
                            сдвинуть чуть-чуть (хотя скрины меня выдадут – и эта речь тоже): в общем, сумбурный шаг
                            получился – но это не так уж и плохо, наверное: течение, длительность, разность состояний.
                        </p>
                        <p className="any-text responsive-text">
                            перекатывать камни
                        </p>
                        <p className="any-text responsive-text">
                            как волны точить
                        </p>
                        <p className="any-text responsive-text">
                            и следы находить
                        </p>
                        <p className="any-text responsive-text">
                            ослепительной вспышки
                        </p>
                        <p></p>
                        <br />
                        <div className="text-title any-text">
                            Шаг 4
                        </div>
                        <p className="any-text responsive-text">
                            [23 июля]
                        </p>
                        <p className="any-text responsive-text">
                            Почувствовала, что сложно придумать новый шаг: протяженность в пространстве – и сложность
                            перманентной вовлеченности в поэтическое – к тому же кажется, что мы совсем разошлись в
                            разные стороны &lt;хотя скорее похоже на волны, которые то смыкаются, то отталкиваются&gt;.
                        </p>
                        <p className="any-text responsive-text">
                            В этот раз получается что-то совсем своё, как рефлексия нашей работы – но тем не менее
                            находятся нечаянно пересечения с репликами предыдущими Ани и Инны.
                        </p>
                        <p className="any-text responsive-text">
                            Ещё неожиданно было – (не сразу заметила) – увидеть кусочек шага, встроенный прямо в мой
                            предыдущий шаг и полностью меняющий его внутренний визуальный образ – как будто другая,
                            более сложная для принятия, степень совместной работы, к которой ещё нужно привыкнуть.
                        </p>
                        <p className="any-text responsive-text">
                            В целом небольшая усталость – но это, наверное, из-за нахождения вне привычного контекста [в
                            бытовом плане]. Очень сложно из-за невозможности редактировать – как будто всё заточено под
                            твоё состояние в один конкретный час времени – а оно не всегда бывает рабочим, и не всегда
                            потом остаёшься доволен получившимся: может быть, спросить/предложить (про) возможность
                            небольшого редактирования в самом конце (наверняка предполагается, не помню).
                        </p>
                        <p className="any-text responsive-text">
                            протяженность в пространстве
                        </p>
                        <p className="any-text responsive-text">
                            как способ фиксировать время
                        </p>
                        <p className="any-text responsive-text">
                            между словом и отражением
                        </p>
                        <p className="any-text responsive-text">
                            в раковине чужой
                        </p>
                        <p></p>
                        <br />
                        <div className="text-title any-text">
                            Шаг 5
                        </div>
                        <p className="any-text responsive-text">
                            [26 июля]
                        </p>
                        <p className="any-text responsive-text">
                            Подумала, что всё равно, конечно, основной темой остаётся коллективное письмо – то есть это
                            изначально мета-проект не только по форме, но и по содержанию. Сложно ориентироваться сразу
                            на обе реплики как будто – сейчас наконец решилась отреагировать на форму Инны (хотела ещё в
                            прошлом шаге, но не хватило сил) – и врезаться в-глубь слов.
                        </p>
                        <p className="any-text responsive-text">
                            Сейчас кажется, что всё-таки хватит и 8 шагов – и завершить так быстро не страшно : уже
                            получается очень полно, много и разрозненно : ещё интересно, будет ли какая-то интенция к
                            соединению в конце. Ещё думаю, что, возможно, стоило больше ограничить себя формально –
                            иначе получается слишком большая свобода /пространственная\тематическая и тд/, которая
                            отчасти скрывает непосредственно исследовательскую сторону (хотя она остаётся, конечно, и в
                            целом сам ТЕКСТ получается очень здорово).
                        </p>
                        <p className="any-text responsive-text">
                            Немного не хватает коммуникации вокруг текстов/реплик : этот дневник – тоже, может быть,
                            восполнение (хотя задумывала изначально как запись именно текстовых ощущений/трансформаций)
                            : думаю, может быть, стоит рассказать о нём – и какую часть показать потом (наверное, всё).
                        </p>
                        <p className="any-text responsive-text">
                            Очень нравится фрагмент, который получается в правом верхнем углу : там действительно
                            складывается какая-то коммуникация/наслоение/разрастание – и при этом сохраняется цельность
                            : остальные фрагменты слишком разрозненные пока что, но ещё несколько шагов есть.
                        </p>
                        <p className="any-text responsive-text">
                            Иногда чувствую желание сдвинуть какую-нибудь уже существующую реплику, чтобы совместились
                            новая и другие прежние (независимо от авторства последних) – и немножко смеюсь над собой :
                            визуальное контролирование неизбывно.
                        </p>
                        <p className="any-text responsive-text">
                            В целом ощущаю всё-таки небольшой недостаток свободы (другой) : работа над визуальным это
                            всегда гармонизирование пространства – а тут отвечаю только за небольшой кусочек, и ничего
                            невозможно поделать с тревогой от итоговой неоднородности – хотя этого не так уж много,
                            просто чувствую иногда.
                        </p>
                        <p className="any-text responsive-text">
                            Местами перестала различать чужие шаги – что было позднее добавлено, что раньше – и
                            авторство тоже иногда (но редко) : может, это и хорошо.
                        </p>
                        <p className="any-text responsive-text">
                            Ещё во время прошлого шага полунамеренно два (три) слова поставила отдельно – посмотреть,
                            нарастёт ли на них что-нибудь – как водоросли на скалах (лоза на ветви).
                        </p>
                        <p className="any-text responsive-text">
                            слово молчания слом
                        </p>
                        <p className="any-text responsive-text">
                            посмотри как спадают падают три
                        </p>
                        <p className="any-text responsive-text">
                            напиши сотри со-пиши
                        </p>
                        <p></p>
                        <br />
                        <div className="text-title any-text">
                            Шаг 6
                        </div>
                        <p className="any-text responsive-text">
                            [31 июля / 1 августа]
                        </p>
                        <p className="any-text responsive-text">
                            Совсем не было времени придумать своё, как было раньше, поэтому почти что прямо перед
                            инпутом погрузилась в предыдущие чужие шаги и стала их дополнять. Этот опыт нечаянный [для
                            моей обычной практики], но тем самым и интересный [хотя я думала таким образом последний шаг
                            сделать, а до него уже скоро совсем – и потому переживаю]: но опыт как раз правильный и
                            замечательный – именно отсутствия своего голоса и слияния с чужим: то есть в этом шаге
                            совсем не будет меня скорее всего, буду только я-часть-текста [заметила, что и другие
                            участницы немного в эту сдвигаются сторону в предыдущем шаге, и действительно становится всё
                            труднее различить, кто есть кто – может, за ними следую].
                        </p>
                        <p className="any-text responsive-text">
                            Точечные дополнение с опорой на текст исключительно – может, получилось как раз от себя
                            отлепиться – до этого было скорее: собственный сюжет, переживание [как обычно с текстами],
                            но адаптированный под окружающие реплики, коммуницирующий с ними – сейчас стратегия
                            категорически изменилась – и, возможно, такой же останется. К 'чужой ракушке' [раковине!
                            позднее дополнила, потому что увидела, что такое именно слово!] пока никто не пришел,
                            интересно, что будет в этот раз [я то местечко оставлю наверное на следующий шаг].
                        </p>
                        <p className="any-text responsive-text">
                            &lt;перенесли инпут
                            , но я решила оставить всё как есть – для чистоты импульса (хотя может ещё передумаю)&gt;
                        </p>
                        <p className="any-text responsive-text">
                            Задумалась, влияет ли на восприятие нами этого проекта выбранная Аней обложка нашего чата в
                            телеграме.
                        </p>
                        <p className="any-text responsive-text">
                            иди
                        </p>
                        <p className="any-text responsive-text">
                            про
                        </p>
                        <p className="any-text responsive-text">
                            слушай
                        </p>
                        <p className="any-text responsive-text">
                            зрения
                        </p>
                        <p className="any-text responsive-text">
                            оттиск
                        </p>
                        <p className="any-text responsive-text">
                            искать
                        </p>
                        <p className="any-text responsive-text">
                            вол
                        </p>
                        <p></p>
                        <br />
                        <div className="text-title any-text">
                            Шаг 7
                        </div>
                        <p className="any-text responsive-text">
                            [7 августа]
                        </p>
                        <p className="any-text responsive-text">
                        Реплику (большую часть) придумала сразу : был большой перерыв, вернулась уже перед шагом и расположила визуально: есть ощущение законченности, как будто не знаю, что добавить ещё, поэтому остальное – небольшие штрихи ; хочется что-то связующее воедино – и пространственно, и во времени, и между нами тремя (или о нас троих – продолжить/закончить). Волнительно, что скоро конец, последний шаг : над ним хочу подумать основательно, сидеть перечитывать много раз – посмотрим, как выйдет.
                        </p>
                        <p className="any-text responsive-text">
                        Кажется, как будто получилось не утратить себя [или не получилось утратить себя] полностью : но при этом обогащение большое чужими языками – особенно языком/способом Инны, постоянная интенция к распадению на кусочки и рассматриванию.
                        </p>
                        <p className="any-text responsive-text">
                        Очень радостно, что Аня увидела небо : как будто то, чего ожидалось – но тем не менее удивительно : подумала, что сегодняшняя реплика моя будет немножко вихрем, если получится : сводки погоды. И еще мы в очень разных пространствах географически, думала много об этом – как и таком соединении тоже.
                        </p>
                        <p className="any-text responsive-text">
                        Переживаю, что с телефона и с компьютера иногда немножко по-разному выглядит : возможно, придётся ещё в конце как-то редактировать/сверять – но по мелочи.
                        </p>
                        <p className="any-text responsive-text">
                        Ещё подумала, что совсем отстранилась от стороны начала условной/своей – только как первая точка, а потом абсолютно в разных местах : не знаю, так и должно было быть – или это я расслабилась : кажется, должно.
                        </p>
                        <p className="any-text responsive-text">
                        [ещё поняла, что ни разу не меняла шаг прямо во время инпута : всегда вводила то, что придумывала заранее : интересно, как это было у других участниц]
                        </p>
                        <p className="any-text responsive-text">
                        в раковине что разворачивается постепенно
                        </p>
                        <p className="any-text responsive-text">
                        id
                        </p>
                        <p className="any-text responsive-text">
                        выросла каждая из
                        </p>
                        <p></p>
                        <br />
                        <div className="text-title any-text">
                        Шаг 8
                        </div>
                        <p className="any-text responsive-text">
                        [10/12 августа]
                        </p>
                        <p className="any-text responsive-text">
                        Инна написала про неохотность – и я поняла, что тоже устала – в том смысле, что не уверена, что и где можно добавить – но сейчас нужно придумать как будто что-то важное – завершить (или наоборот не придумывать – устраниться тихонько).
                        </p>
                        <p className="any-text responsive-text">
                        Пыталась всё перечитать/прочитать – и поняла, что это ужасно сложно сейчас – и всё кажется очень разрозненным : даже завидую тем, кто увидит это впервые сразу как единое целое : может быть – и вероятно даже – они больше смогут сказать об этом тексте, чем мы сами.
                        </p>
                        <p className="any-text responsive-text">
Думаю ещё об этом дневнике как о дополнительной индивидуальной работе. Стоило ли делать это? Не отвлекло ли меня его ведение от чего-то, не помешало ли влиться в другие речевые потоки? С другой стороны – много удивительных открытий для себя самой в разнице ощущений, освоении пространства/соседства/диалога/коммуникации/других языков. Изменилась ли наша цель в течение написания? Стоило ли нам больше разговаривать об этом? Сблизились мы или разошлись? Думаю, ещё поговорим об этом – но сейчас, перед последним шагом, всё кажется таким же неясным. Хотя одно очевидно – что это точно повлияло на письмо собственное – что много нового узналось/почувствовалось/удалось прихватить себе.
                        </p>
                        <p className="any-text responsive-text">
В моих текстах последние фразы всегда самые важные, а последние визуальные шаги – структурирующие пространство – поэтому, может быть, и сложно придумать, как здесь выстроить завершение.
Ещё думала о том, что хронологически последовательные фразы/ответы в визуальности обретают иную последовательность – и это бывает сложно уловить при долгом создании.
                        </p>
                        <p className="any-text responsive-text">
[ничего не получилось придумать вовремя, поэтому предложила перенести : всё-таки последний шаг это как-то очень волнительно, потому что я выбрала, видимо, всё-таки завершающую стратегию]
                        </p>
                        <p className="any-text responsive-text">
[опять вернулась совсем накануне – и опять ничего не могу придумать : наверное, он действительно закончен уже так или иначе – нужно только поставить точку.]
                        </p>
                        <p className="any-text responsive-text">
[поэтическая филигранность : попросила отложить шаг ради двух слов! : но действительно ничего невозможно больше]
                        </p>
                        <p className="any-text responsive-text">
Ещё подумала, что почти не ориентировалась на свои же шаги (кроме раковины) – и даже забыла немного, что там писала.
                        </p>
                        <p className="any-text responsive-text">
Ну что ж, вот мы это и сделали!
                        </p>
                        <p className="any-text responsive-text">
точка
                        </p>
                        <p className="any-text responsive-text">
тишина
                        </p>

                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
