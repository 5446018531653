import React, {useEffect, useState} from 'react';
import StickySection from './sticky/StickySection';
import './App.css';
import Areals from "./components/areals/Areals";
import {Beige, Green, LightBeige} from "./components/_inner/Constants";
import Menu from "./components/menu/Menu";
import About from "./components/about/About";
import Process from "./components/process/Process";
import StickySectionDynamic from "./sticky/StickySectionDynamic";
import {Diary} from "./components/about/Diary";

const sections = [
    { id: 'section1', textColor: '#000000' },
    { id: 'section2', textColor: '#ffffff' },
    { id: 'section3', textColor: '#000000' }
];
const App: React.FC = () => {
    useEffect(() => {
        const handleScroll = () => {
            const section2 = document.getElementById('section2');
            const section3 = document.getElementById('section3');

            if (section2 && section3) {
                const { bottom } = section2.getBoundingClientRect();
                if (bottom <= window.innerHeight) {
                    section3.style.position = 'sticky';
                    section3.style.top = '0px';
                } else {
                    section3.style.position = 'relative';
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [openPismo, setOpenPismo] = useState(false)
    const [currentTextColor, setCurrentTextColor] = useState(sections[0].textColor);
    const [openDairy, setOpenDairy] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            let selectedColor = sections[0].textColor;
            let maxVisibleHeight = 0;

            const sectionElement = document.getElementById('section2');
                if (sectionElement) {
                    const rect = sectionElement.getBoundingClientRect();

                    const visibleHeight = Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
                    if (visibleHeight > maxVisibleHeight && rect.top <= 0) {
                        selectedColor = '#ffffff'
                    }
                }
            setCurrentTextColor(selectedColor);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            {openDairy && <Diary setOpen={setOpenDairy}/>}
            {!openDairy && <>
            <Menu textColor={currentTextColor} isOpenPismo={openPismo}/>
            <StickySection color={LightBeige} id="section1">
                <Areals  openPismo={openPismo} setOpenPismo={setOpenPismo}/>
            </StickySection>
            <StickySectionDynamic color={Green} id="section2" >
                <About setOpen={setOpenDairy}/>
            </StickySectionDynamic>
            <StickySection color={Beige} id="section3">
                <Process/>
            </StickySection>
            </>
            }
        </div>
    );
}

export default App;
