import React, {useState} from "react";
import "./Process.css"
import {isMobileDeviceV2} from "../../utils/StyleUtils";
// @ts-ignore
import Vimeo from '@u-wave/react-vimeo';

const Process : React.FC = () => {
    const [currentIndex] = useState(0);
    const isMob = isMobileDeviceV2();

    type SlideContent = {
        type: 'photo' | 'video';
        src: string;
        alt?: string;
    };

    const vimeo_id = "948218557";

    const slides: SlideContent[] = [];
    // const goToPrevious = () => {
    //     setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
    // };
    //
    // const goToNext = () => {
    //     setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    // };

    const renderSlideContent = (slide: SlideContent) => {
        return (
            <Vimeo video={vimeo_id} width="1000" height="800" dnt={true} showTitle={false} loop={true} showPortrait={false} showByline={false} />
        );
    };

    const renderSlideContentMob = (slide: SlideContent) => {
        return (
            <Vimeo className={"videoPlayer"} video={vimeo_id} dnt={true} showTitle={false} loop={true} showPortrait={false} showByline={false} />
        );
    };

    return (
        <div className={"process main-style"} id={"process"}>
            <div>
              {!isMob && renderSlideContent(slides[currentIndex])}
              {isMob && renderSlideContentMob(slides[currentIndex])}
            </div>
        </div>
    )
}

export default Process
