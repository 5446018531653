export const LightBeige = "#EBE8E6"
export const White = "#FFFFFF"
export const DarkBegie = "#605A5E"
export const Green = "#828282"
export const Beige = "#BEB8AC"
export const Black = "#070707"

export let PageColors: Record<number, string> = {
    1: LightBeige,
    2: Green,
    3: Beige,
}