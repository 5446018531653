import React, { useRef, useEffect, useState } from 'react';

const StickySectionDynamic: React.FC<{ color: string; id: string; children: React.ReactNode }> = ({ color, id, children }) => {
    const sectionRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState('100vh');

    useEffect(() => {
        const adjustHeight = () => {
            if (sectionRef.current) {
                const scrollHeight = sectionRef.current.scrollHeight;
                setHeight(`${scrollHeight}px`);
            }
        };

        adjustHeight();
        window.addEventListener('resize', adjustHeight);

        return () => {
            window.removeEventListener('resize', adjustHeight);
        };
    }, [children]);

    return (
        <div ref={sectionRef} id={id} style={{
            minHeight: '100vh',
            height: height,
            backgroundColor: color,
            position: 'relative',
            zIndex: id === "section2"? 10 : 1
        }}>
            {children}
        </div>
    );
};

export default StickySectionDynamic;
