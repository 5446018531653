import React from "react";
import x from ".././../static/x.svg"
import plus from ".././../static/plus.svg"
import minus from ".././../static/minus.svg"
import "./Inner.css"

type SettingsProps = {
    setOpenPismo: React.Dispatch<boolean>
    zoomIn: React.Dispatch<any>
    zoomOut: React.Dispatch<any>
}

 export type CloseProps = {
    setOpen: React.Dispatch<boolean>
}
export const Settings: React.FC<SettingsProps> = (SettingsProps) => {
    return (
        <div >
            <div onClick={SettingsProps.zoomIn} className={"settings-bottoms plus"} style={{}}>
                <img src={plus} alt={""}/>
            </div>
            <div onClick={SettingsProps.zoomOut} className={"settings-bottoms minus"}>
                <img src={minus} alt={""}/>
            </div>
            <div onClick={() => {
                SettingsProps.setOpenPismo(false)
            }} className={"settings-bottoms x"}>
                <img src={x} alt={""}/>
            </div>
        </div>
    )
}

export const CloseButton: React.FC<CloseProps> = (CloseProps) => {
    return (
            <div onClick={() => {
                CloseProps.setOpen(false)
            }} style={{zIndex:"100"}} className={"settings-bottoms x"}>
                <img src={x} alt={""}/>
            </div>
    )
}

