import elipsefill from "../../static/ellipse-fill.svg";
import elipse from "../../static/ellipse.svg";
import React from "react";
import {isMobileDeviceV2} from "../../utils/StyleUtils";

type NamesProps = {
    inna: boolean,
    setInna: React.Dispatch<boolean>,
    anya: boolean,
    setAnya: React.Dispatch<boolean>,
    darya: boolean,
    setDarya: React.Dispatch<boolean>,
}
const Names: React.FC<NamesProps> = (namesProps) => {
    return (
        <div className={"names-absolut"}>
            <ul className={"names"} style={isMobileDeviceV2()? {display: "block"}:{}}>
                <li>
                    <ul className={"names-inner"}>
                        <li><img className={"but"} onClick={() => {
                            namesProps.setInna(!namesProps.inna)
                        }} src={namesProps.inna ? elipsefill : elipse} alt={"elipse"}/></li>
                        <li>
                            <div className={"areals-text"}>Инна</div>
                        </li>
                    </ul>
                </li>
                <li>
                    <ul className={"names-inner"}>
                        <li><img className={"but"} onClick={() => {
                            namesProps.setAnya(!namesProps.anya)
                        }} src={namesProps.anya ? elipsefill : elipse} alt={"elipse"}/></li>
                        <li>
                            <div className={"areals-text"}>Анна</div>
                        </li>
                    </ul>
                </li>
                <li>
                    <ul className={"names-inner"}>
                        <li><img className={"but"} onClick={() => {
                            namesProps.setDarya(!namesProps.darya)
                        }} src={namesProps.darya ? elipsefill : elipse} alt={"elipse"}/></li>
                        <li>
                            <div className={"areals-text"}>Дарья</div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    )
}

export default Names
