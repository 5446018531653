import React, {useEffect, useState} from 'react'
import "./Areals.css"
import elipse from "../../static/ellipse.svg"
import elipsefill from "../../static/ellipse-fill.svg"
import lupe from "../../static/lupa.svg"
import Names from "../_inner/Names";
import all from "../../static/miro/all.webp"
import nobody from "../../static/miro/nobody.webp"
import anya_inna from "../../static/miro/anya+inna.webp"
import anya_dasha from "../../static/miro/anya+dasha.webp"
import inna_dasha from "../../static/miro/inna+dasha.webp"
import inna_ from "../../static/miro/inna.webp"
import dasha_ from "../../static/miro/dasha.webp"
import anya_ from "../../static/miro/anya.webp"
import {Settings} from "../_inner/Settings";
import {isMobileDeviceV2, isMobileDeviceV3} from "../../utils/StyleUtils";

type ArealsProps = {
    openPismo: boolean,
    setOpenPismo: React.Dispatch<boolean>
}
const Areals: React.FC<ArealsProps> = (arealsProps: ArealsProps) => {
    const [scale, setScale] = useState(1); // Default scale is 1
    const [inna, setInna] = useState(true)
    const [anya, setAnya] = useState(true)
    const [dasha, setDasha] = useState(true)
    const [miro, setMiro] = useState(all)

    useEffect(() => {
        if (inna && anya && dasha){
            setMiro(all)
        } else if (!inna && !anya && !dasha){
            setMiro(nobody)
        } else if (inna && anya) {
            setMiro(anya_inna)
        } else if (dasha && anya) {
            setMiro(anya_dasha)
        } else if (dasha && inna) {
            setMiro(inna_dasha)
        } else if (inna) {
            setMiro(inna_)
        } else if (dasha) {
            setMiro(dasha_)
        } else if (anya) {
            setMiro(anya_)
        }
    }, [inna, anya, dasha]);

    const zoomIn = () => {
        if (scale*1.1 > 5.7){
            return
        }
        setScale(scale => scale * 1.1); // Increase scale by 10%
    };

    const zoomOut = () => {
        setScale(scale => Math.max(scale / 1.1, 1)); // Decrease scale by 10%, minimum scale is 1
    };

    const isMob = isMobileDeviceV2()


    return (
        <div className={arealsProps.openPismo ? "areals" : "areals main-style"} id={"areals"}>
            <div className={"miro-wrapper"}>
            <div style={arealsProps.openPismo? {height: "100vh", margin: "0", width: "100vw"} : {}} className={"miro"}>
                <img style={{ transform: `scale(${scale})` }} className={"miro-img"} src={miro} alt={"dsa"} />
                {arealsProps.openPismo && <Names anya={anya} darya={dasha} setAnya={setAnya} setDarya={setDasha} inna={inna} setInna={setInna} />}
                {arealsProps.openPismo && <Settings zoomIn={zoomIn} zoomOut={zoomOut} setOpenPismo={arealsProps.setOpenPismo} />}
            </div>
                <div style={{paddingTop: "1rem"}}>
                    {!arealsProps.openPismo &&
                        <ul className={"names"}>
                            { !isMob && <ul className={"names-wrapper"}>
                            <li>
                                <ul className={"names-inner"}>
                                    <li><img className={"but"} onClick={() => {
                                        setInna(!inna)
                                    }} src={inna ? elipsefill : elipse} alt={"elipse"}/></li>
                                    <li>
                                    <div className={"areals-text"}>Инна</div>
                            </li>
                                </ul>
                            </li>
                            <li>
                                <ul className={"names-inner"}>
                                    <li><img className={"but"} onClick={() => {
                                        setAnya(!anya)
                                    }} src={anya ? elipsefill : elipse} alt={"elipse"}/></li>
                                    <li>
                                        <div className={"areals-text"}>Анна</div>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <ul className={"names-inner"}>
                                    <li><img className={"but"} onClick={() => {
                                        setDasha(!dasha)
                                    }} src={dasha ? elipsefill : elipse} alt={"elipse"}/></li>
                                    <li>
                                        <div className={"areals-text"}>Дарья</div>
                                    </li>
                                </ul>
                            </li>
                            </ul>}
                            <ul className={"names-wrapper"} style={isMob? {position: "absolute", top: "12vh", left:"3vh"}:{}}>
                                <li style={{ marginRight: "0"}}>
                                    <ul className={"names-inner"} style={{marginLeft: "1rem"}}>
                                        <li><img style={isMob? {width:"35px", height:"35px"}:{}} className={"but"} onClick={() => {
                                            arealsProps.setOpenPismo(true)
                                        }} src={lupe} alt={"elipse"}/></li>
                                        <li style={isMobileDeviceV3(820)? {display: "none"} : {}}>
                                            <div className={"areals-text"}>открыть на весь экран</div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                        </ul>
                    }
                </div>
            </div>
        </div>
    )
}

export default Areals
