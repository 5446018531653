import React from "react";
import "./About.css"
import {isMobileDeviceV2} from "../../utils/StyleUtils";
import {White} from "../_inner/Constants";
import {CloseProps} from "../_inner/Settings";

const About: React.FC<CloseProps> = (CloseProps) => {
    let isMob = isMobileDeviceV2()
    const borderStyle = "2px solid " + White
    return (
        <div className={"about main-style"} id={"about"}>
            <div className={"grid"}>
                <div className={"grid-item xs-2"} style={{borderBottom: borderStyle}}>
                    <p className={"p-zero any-text responsive-text"}>«Ареалы» — работа, появившаяся из практики
                        совместного письма трех
                        поэтесс: <a href="https://msha.ke/innakrasnoper#links" target="_blank" rel="noreferrer">Инны Краснопер</a>, <a href="https://msha.ke/annarodionova" target="_blank" rel="noreferrer">Анны Родионовой</a> и <a href="https://msha.ke/dadaria" target="_blank" rel="noreferrer">Дарьи Фоменко</a>. Несколько раз на протяжении месяца в
                        заранее определенное время мы вносили свои реплики в общее цифровое пространство, дополняя
                        написанное нами ранее и синтезируя среду взаимодействия. Насыщенностью текста показано движение
                        во времени: ранние записи самые темные, поздние — самые прозрачные. Способы контакта с местом и
                        записями друг друга могли быть разными: от разметки, попыток мимикрии, робкого стилистического
                        сближения до игры и противоречия. Что значит располагаться в одном пространстве? Быть рядом и
                        действовать? Как можно определить границы? Могут ли возникнуть места встречи? И, если да, то как
                        в них или на пути к ним изменятся наши языки? Как учесть уязвимые зоны, находясь по соседству?
                        Эти вопросы, как нам кажется, касаются сейчас далеко не только письма. Мы попробовали
                        исследовать их на практике.</p>
                </div>
                <div className={"grid-item xs-1"}
                     style={isMob ? {borderBottom: borderStyle} : {borderRight: borderStyle}}>
                    <p style={{marginTop: 0}} className={"any-text responsive-text"}>пространство общего. общения.
                        маркирующее. марающее. слипшееся.
                        вбирающее. выбирающее раз. запоминающее. утопающее</p>
                    <p className={"p-zero any-text responsive-text"}>пространство отстраивания от. усложняющее.
                        упрощающее через
                        сложность. каждый следующий шаг приближает или удаляет</p>
                    <p className={"p-zero any-text  responsive-text"}>вдоль или
                        поперёк</p>
                    <p className={"p-zero any-text responsive-text"}>пространство имеющее более двух координат</p>
                    <p className={"p-zero any-text responsive-text"}>говорящее с нами. безднами. безъязыко плесенью
                        чистенькой</p>
                    <p className={"p-zero any-text responsive-text"}>просто знаки при знаки при</p>
                    <p className={"p-zero any-text responsive-text"}>сесть. уходить в лужу. в loosen</p>
                    <p className={"p-zero any-text responsive-text"}>вливать врезать</p>
                    <p className={"p-zero any-text responsive-text"}>растущее раз</p>
                    <p className={"p-zero any-text responsive-text"}>дельно</p>
                    <p className={"p-zero any-text responsive-text"}>прикладывая усики. отслеживая от рук</p>
                    <p className={"p-zero any-text responsive-text"}>ребя</p>
                    <p></p>
                    <div className={"text-title any-text responsive-title"}>ИННА</div>
                </div>
                <div className={"grid-item xs-1"} style={isMob ? {borderBottom: borderStyle} : {paddingLeft: "1.5rem"}}>
                    <p style={{marginTop: '0'}} className={"any-text responsive-text"}>На мой взгляд, эта работа является попыткой
                        прикосновения сразу к нескольким проблемам. С одной стороны, здесь поставлен вопрос о возможности/невозможности
                        общего языка — стилистического, понятийного, визуального, территориального. Но и общего языка в бытовом смысле,
                        то есть просто способности договариваться. Поэтому очень важно было сделать эту работу непредсказуемой и процессуальной.
                        Здесь нет общей точки, в которой всё должно собраться, ведь поиск общего языка не обязательно предполагает успешную коммуникацию.</p>

                    <p className={"any-text responsive-text"}>
                        Поэтому этот проект получился почти исследовательским. Что было бы, если бы три разных способа
                        быть на границе языка попробовали найти себя и друг друга из разных точек? Я думаю, поэтому состав
                        участниц оказался именно таким: на фоне довольно непохожих поэтик, наши способы изучать пограничья
                        языка с помощью кроссмедиальных практик дополняют друг друга.
                    </p>

                    <p className={"any-text responsive-text"}>
                        Наконец, здесь есть место и проблематизации индивидуального авторства. Я считаю, что писать
                        по-настоящему в одиночку, изолировано от других людей и процессов, невозможно: письмо — это то,
                        что конституируется средой, иными текстами и практиками, вайбами, мыслями других, поддержкой тех,
                        кто рядом, едва заметными вещами. Здесь этой неединичности хотелось дать свободу и видимость.
                    </p>
                    <div className={"text-title any-text responsive-title"}>АНЯ</div>
                </div>
                <div className={"grid-item xs-2 any-text"} style={isMob ? {borderBottom: borderStyle} : {borderBottom: borderStyle, borderTop: borderStyle}}>
                    <p className={"any-text responsive-text"}>Для меня это проект об исследовании письма и его
                        субъективности :
                        (не)возможность разделить говорение (и его структурирование) с другим, постоянное нащупывание
                        границ и стремление к диалогу-полилогу, в ходе которого создается единое целое общего текста;
                        балансирование между ограничениями – временными, пространственными, смысловыми – и свободой
                        откликаться на чужое, иметь его в виду здесь и сейчас – иногда сложно, иногда интересно – но в
                        целом абсолютно удивительно – как еще одна попытка обнаружить схожее/различное и до чего-нибудь
                        договориться.
                    </p>
                    <div className={"text-title any-text responsive-title"}>ДАША</div>
                </div>
                <div className={"grid-item xs-2 any-text"}>
                    <p></p>
                <div className={"text-title any-text"}>ДНЕВНИК</div>
                <p className={"text-title any-text responsive-text"}>
                Шаг 1
                </p>
                <p className={"any-text responsive-text"}>
                Переживала, что мой первый шаг будет сверху – устрашающая белая пустота : почему-то в моей голове всегда
                представлялись ходы снизу.
                Концепт первого шага придумала, пока шла по улице и смотрела на облака : внутреннее болезненное ощущение
                разрозненности совместилось с ощущением, что небо остаётся целостным, хотя иногда в каждом его кусочке
                выглядит по-разному. Подумала, что это подходит и под проект, гипотетически и формально объединяющий
                разные языки.
                </p>
                <div onClick={() => {CloseProps.setOpen(true)}} style={{textDecoration: "underline", cursor: "pointer"}}
                     className={"text-title any-text"}>...ЧИТАТЬ ПОЛНОСТЬЮ
                </div>
                <p></p>
                <div className={"text-title any-text"}>ДАША</div>
                </div>
            </div>
        </div>
    )
}

export default About;
