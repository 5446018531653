import React, {useState} from "react";
import "./Menu.css"
import "./Burger.css"


type MenuProps = {
    isOpenPismo: boolean
    textColor: string
}
const Menu: React.FC<MenuProps> = (MenuProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const scrollToElement = (elementId: string) => {
        setIsOpen(false);
        if (elementId === "section1") {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            return
        }
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };
    if (MenuProps.isOpenPismo){
        return (
            <></>
        )
    }
    return (

        <div className={"wrapper-menu"}>
        <div className={"menu"}>
                    <span id={"areals-menu"} className="text" style={!isOpen? {color: MenuProps.textColor}: {opacity: "0", color: MenuProps.textColor} }>АРЕАЛЫ</span>
                    <span className="icon">
                        <div id="burger" className={`burger-icon ${isOpen ? "cross" : ""}`} onClick={toggleMenu}>
                          <span style={{backgroundColor:`${isOpen? "white": MenuProps.textColor}`}} id={"areals-menu-span-1"} ></span>
                          <span style={{backgroundColor:`${isOpen? "white": MenuProps.textColor}`}} id={"areals-menu-span-2"}></span>
                        </div>
                    </span>
            {isOpen && <> <div className={"opened"}>
                <div className="text-container">
                    <div className="title text text-opened" onClick={() => scrollToElement('section1')}>АРЕАЛЫ</div>
                    <div className="title text text-opened" onClick={() => scrollToElement('section2')}>О ПРОЕКТЕ</div>
                    <div className="title text text-opened" onClick={() => scrollToElement('section3')}>ПРОЦЕСС</div>
                </div>
            </div>
            </> }
        </div>
        </div>
    );
};

export default Menu;